<template>
  <v-dialog v-model="modalData.dialog" max-width="950px" persistent scrollable>
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("WEBSHOP.customer") }}
      </v-btn>
    </template> -->

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>

            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <!-- <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-model="selectedLocale"
                :items="languages"
                :disabled="languages.length == 1"
              >
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.products"
                v-model="formModel.status"
                :items="statuses.products.product"
                label="Status"
                item-text="value"
                item-value="key"
              />
            </v-col>
          </v-row> -->

          <v-row>
            <v-col cols="12" sm="5" md="5">
              <v-text-field
                v-if="formModel"
                v-model="formModel.surname"
                :label="$t('FORMS.surname')"
                :id="dynamicID"
                :error-messages="messages['surname']"
                @keyup="messages['surname'] = ''"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="5" md="5">
              <v-text-field
                v-if="formModel"
                v-model="formModel.christian_name1"
                :label="$t('FORMS.name')"
                :rules="nameRules"
                :id="dynamicID"
                :error-messages="messages['christian_name1']"
                @keyup="messages['christian_name1'] = ''"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2" v-if="formModel.ac_contact">
              <v-btn
                color="primary"
                class="ml-5"
                text
                @click="handleShowACForm"
              >
                {{ $t("FORMS.ac_contact") }}
              </v-btn>
            </v-col>
            <!-- <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-if="formModel"
                v-model="formModel.christian_name2"
                :label="$t('FORMS.name') + 2"
                :id="dynamicID"
                :error-messages="messages['christian_name2']"
                @keyup="messages['christian_name2'] = ''"
              ></v-text-field>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-if="formModel"
                v-model="formModel.email"
                label="Email"
                :rules="nameRules"
                :id="dynamicID"
                :error-messages="messages['email']"
                @keyup="messages['email'] = ''"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-if="formModel && formModel.custom_fields"
                v-model="formModel.custom_fields.phone"
                :label="$t('TABLE.phone')"
                :id="dynamicID"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-if="
                  formModel &&
                  formModel.custom_fields &&
                  formModel.custom_fields.reseller
                "
                v-model="formModel.custom_fields.resellerLicence"
                :label="$t('FORM_INPUT_NAMES.reseller_license')"
                :id="dynamicID"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="formModel.custom_fields">
            <v-col cols="12" sm="6" md="6">
              {{ $t("WEBSHOP.shipping_address") }}

              <ul>
                <li
                  v-for="(address, index) in formModel.custom_fields
                    .shippingAddresses"
                  :key="index"
                >
                  <p>
                    {{ address.country }} {{ address.zip_code }},
                    {{ address.city }} {{ address.address }}
                  </p>
                </li>
              </ul>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              {{ $t("WEBSHOP.billing_address") }}
              <ul>
                <li
                  v-for="(address, index) in formModel.custom_fields
                    .billingAddresses"
                  :key="index"
                >
                  <span v-if="address.is_company">
                    <b v-if="address.is_company"
                      >Cég: {{ address.company_name }}
                    </b>
                    <br />
                    <b v-if="address.is_company">
                      Adószám: {{ address.tax_number }}
                    </b>
                  </span>
                  <b v-else>
                    {{ address.name }}
                  </b>
                  <p>
                    {{ address.country }} {{ address.zip_code }},
                    {{ address.city }} {{ address.address }}
                  </p>
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-divider
            v-if="formModel.orders && formModel.orders.length > 0"
          ></v-divider>
          <v-row v-if="formModel.orders && formModel.orders.length > 0">
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="formModel.orders"
                :sort-by="['id']"
                sort-desc="true"
                @click:row="handleClickItem"
              >
                <template v-slot:item.amount="{ item }">
                  {{ item.custom_fields.amount }} Ft
                </template>
                <template v-slot:item.date="{ item }">
                  <span>
                    {{
                      new Date(item.created_at).toLocaleString("hu-HU", {
                        timeZone: "UTC",
                      })
                    }}
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="mb-2">
        <!-- <v-btn color="blue" @click="handleCloseModalForm">
          <span>{{ $t("FORMS.cancel") }}
        </v-btn> -->
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./Customers";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
// import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";

const TRANSLATED_ATRIBUTES = [];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [];

export const initialFormData = () => ({
  id: null,
  status: 1,
  christian_name1: null,
  christian_name2: null,
  surname: null,

  translations: {},
  products: [],
  // custom_fields: [],
});

export default {
  name: "PageForm",
  props: [
    "modalData",
    "permissions",
    "statuses",
    "endPoint",
    "customerCollection",
  ],
  // components: { CustomFieldComponent },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,

      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      // editorConfig: {
      //   htmlEncodeOutput: false,
      //   entities: false,
      //   extraPlugins: "justify,font,copyformatting",
      //   filebrowserBrowseUrl:
      //     window.location.origin +
      //     "/" +
      //     this.$router.resolve({
      //       name: "media_library",
      //       query: { component_in_window: 1 },
      //     }).href +
      //     "?type=Files",
      //   filebrowserImageBrowseUrl:
      //     window.location.origin +
      //     "/" +
      //     this.$router.resolve({
      //       name: "media_library",
      //       query: { component_in_window: 1 },
      //     }).href +
      //     "?type=Images",
      // },

      previewable_mime_types: [
        "image/png",
        "image/jpeg",
        "image/x-ms-bmp",
        "image/webp",
        "image/gif",
        "image/svg+xml",
      ],
      files: [],
      headers: [
        {
          text: this.$t("TABLE.date"),
          value: "date",
        },
        { text: "Azonosító", value: "order_number" },
        {
          text: this.$t("TABLE.amount"),
          value: "amount",
        },
      ],
    };
  },
  computed: {
    // ...mapGetters(["productCollection"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") + this.formModel.name
        : this.$t("MENU.NEW") + " " + this.$t("WEBSHOP.customer");
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.userLanguages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
              // this.formModel.custom_fields = this.initCustomFields();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.setTranslatedAttributes();

          // this.formModel.custom_fields = this.initCustomFields();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },

    "formModel.products": function (newVal) {
      this.files = this.calculateFiles(newVal);
    },
  },
  methods: {
    // ...mapActions(["fetchProduct"]),
    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );
      model.roles = model.roles.map((item) => item.id);
      model.sites = model.sites.map((item) => item.id);
      model.user_groups = model.user_groups.map((item) => item.id);
      model.products = this.files.map((file) => file.id);
      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;

        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    calculateFiles() {
      if (!this.formModel.products) return [];

      let f = [];
      let product;
      this.formModel.products.forEach((id) => {
        product = this.productCollection.find((product) => product.id == id);

        product.productName =
          product.translations[this.selectedLocale.lang].name;

        let noImage = {
          name: "no_image.png",
          path: "img/no_image.png",
          size: 1,
          user: "",
          mime_type: "image/jpeg",
          created_at: "2022-01-19 13:27:07",
        };
        if (
          !product.translations[this.selectedLocale.lang].custom_fields.image ||
          !product.translations[this.selectedLocale.lang].custom_fields.image[0]
        ) {
          product.file = noImage;
        } else {
          product.file =
            product.translations[
              this.selectedLocale.lang
            ].custom_fields.image[0];
        }

        f.push(product);
      });

      return f;
    },

    getFile(file) {
      return (
        process.env.VUE_APP_BACKEND_URL.replace(/\/+$/, "") + "/" + file.path
      );
    },

    deleteChip(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove.id) {
          array.splice(i, 1);
        }
      }
    },

    handleShowACForm() {
      if (this.formModel.ac_contact && this.formModel.ac_contact.id) {
        window.open(
          process.env.VUE_APP_AC_LINK + this.formModel.ac_contact.id,
          "_blank"
        );
      }
    },

    handleClickItem(item) {
      const routeData = this.$router.resolve({
        path: `/webShop/orders/` + item.id,
      });
      window.open(routeData.href, "_blank");
    },
  },

  mounted() {
    // this.fetchProduct();
    this.setTranslatedAttributes();
  },
};
</script>


